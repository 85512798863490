import maplibregl from 'maplibre-gl';
import gcoord from 'gcoord';
import { useEffect } from 'react';
import { map } from './core/MapView';
import { getPersistedState } from '../common/util/usePersistedState';

class MyGeolocateControl extends maplibregl.GeolocateControl {
  /* eslint no-underscore-dangle: 0 */
  constructor(options, coordinatesType) {
    super(options);
    this.coordinatesType = coordinatesType;
  }

  _updateMarker(position) {
    if (position) {
      let { longitude, latitude } = position.coords;
      if (this.coordinatesType !== gcoord.WGS84) {
        const [lng, lat] = gcoord.transform([longitude, latitude], gcoord.WGS84, this.coordinatesType);
        longitude = lng;
        latitude = lat;
      }
      const center = new maplibregl.LngLat(longitude, latitude);
      this._accuracyCircleMarker.setLngLat(center).addTo(this._map);
      this._userLocationDotMarker.setLngLat(center).addTo(this._map);
      this._accuracy = position.coords.accuracy;
      if (this.options.showUserLocation && this.options.showAccuracyCircle) {
        this._updateCircleRadius();
      }
      setTimeout(() => {
        this._map.setCenter(center);
      }, 1000);
    } else {
      this._userLocationDotMarker.remove();
      this._accuracyCircleMarker.remove();
    }
  }
}

const MapCurrentLocation = () => {
  const coordinatesType = getPersistedState('coordinatesType', gcoord.GCJ02);
  useEffect(() => {
    const control = new MyGeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
      },
      trackUserLocation: true,
    }, coordinatesType);
    map.addControl(control);
    return () => map.removeControl(control);
  }, [coordinatesType]);

  return null;
};

export default MapCurrentLocation;
