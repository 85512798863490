import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from './LocalizationProvider';
import { useCatch } from '../../reactHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(1)})`,
    },
  },
  button: {
    height: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.colors.negative,
  },
}));

const RemoveDialog = ({
  open, endpoint, itemId, onResult, title = 'sharedRemoveConfirm', content = null,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const handleRemove = useCatch(async () => {
    const response = await fetch(`/api/${endpoint}/${itemId}`, { method: 'DELETE' });
    if (response.ok) {
      onResult(true);
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <Dialog
      className={classes.root}
      open={open}
    >
      <DialogTitle>{t(title)}</DialogTitle>
      {content && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(content)}
        </DialogContentText>
      </DialogContent>
      )}
      <DialogActions>
        <Button size="small" variant="contained" color="error" sx={{ mr: 2 }} onClick={handleRemove}>{t('sharedRemove')}</Button>
        <Button size="small" variant="contained" onClick={() => onResult(false)}>{t('sharedCancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
