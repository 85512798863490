import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import { styled } from '@mui/material/styles';
import {
  useMediaQuery,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Tabs,
  Tab,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RouteIcon from '@mui/icons-material/Route';
import gcoord from 'gcoord';

import { useTranslation } from './LocalizationProvider';
import RemoveDialog from './RemoveDialog';
import PositionValue from './PositionValue';
import { useDeviceReadonly, useAdministrator, useRestriction } from '../util/permissions';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { devicesActions } from '../../store';
import { useCatch, useCatchCallback } from '../../reactHelper';
import { useAttributePreference, usePreference } from '../util/preferences';
import {
  getStatusColorHex,
  getStatusColorHexByName,
  formatDeviceStatusDesc,
  formatAlarm,
  formatTime,
} from '../util/formatter';
import theme from '../theme';
import { getPersistedState } from '../util/usePersistedState';
import { ReactComponent as GeofenceIcon } from '../../resources/images/icon/statusCard/geofence.svg';
import { ReactComponent as SendCommandIcon } from '../../resources/images/icon/statusCard/send-command.svg';
import { ReactComponent as TrackReplayIcon } from '../../resources/images/icon/statusCard/track-replay.svg';
import { ReactComponent as WechatNotificationIcon } from '../../resources/images/icon/statusCard/wechat-notification.svg';
import { ReactComponent as EditDeviceIcon } from '../../resources/images/icon/statusCard/edit-device.svg';
import { ReactComponent as DeleteDeviceIcon } from '../../resources/images/icon/statusCard/delete-device.svg';
import { ReactComponent as StreetViewIcon } from '../../resources/images/icon/statusCard/street-view.svg';
import { ReactComponent as CloseIcon } from '../../resources/images/icon/statusCard/close.svg';
import { ReactComponent as MultiAdminIcon } from '../../resources/images/icon/statusCard/multi-admin.svg';

const useStyles = makeStyles((theme) => {
  const width = theme.breakpoints.down('md') ? theme.dimensions.popupMaxWidth * 0.9 : theme.dimensions.popupMaxWidth;
  const cellTitleMaxWidth = 90;
  return {
    card: {
      pointerEvents: 'auto',
      width,
      borderRadius: 8,
    },
    media: {
      height: theme.dimensions.popupImageHeight,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    CloseIcon: {
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 1, 0, 2) / 2,
    },
    cardHeader: {
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      paddingLeft: theme.spacing(0.8),
      paddingRight: theme.spacing(0.6),
    },
    content: {
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      paddingLeft: theme.spacing(0.8),
    },
    tab: {
      padding: 0,
      minHeight: 50,
      lineHeight: 0.8,
      minWidth: 60,
      maxWidth: 100,
      fontSize: 12,
    },
    icon: {
      width: '25px',
      height: '25px',
      filter: 'brightness(0) invert(1)',
    },
    table: {
      '& .MuiTableCell-sizeSmall': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    cellTitle: {
      maxWidth: cellTitleMaxWidth,
      borderBottom: 'none',
      padding: 0.05,
      whiteSpace: 'normal',
    },
    cellContent: {
      maxWidth: width - cellTitleMaxWidth,
      borderBottom: 'none',
      padding: 0.05,
      whiteSpace: 'normal',
    },
    actions: {
      padding: 0,
    },
    root: ({ desktopPadding }) => ({
      pointerEvents: 'none',
      position: 'fixed',
      zIndex: 5,
      left: '50%',
      [theme.breakpoints.up('md')]: {
        left: `calc(50% + ${desktopPadding} / 2)`,
        bottom: theme.spacing(3),
      },
      [theme.breakpoints.down('md')]: {
        left: '50%',
        bottom: `calc(${theme.spacing(1)} + ${theme.dimensions.bottomBarHeight}px)`,
      },
      transform: 'translateX(-50%)',
    }),
  };
});

const StyledBadge = styled(Badge)(({ theme, device }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: getStatusColorHex(device.status),
    color: getStatusColorHex(device.status),
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cellTitle}>
        <Typography variant="subtitle2" sx={{ color: '#181818', marginLeft: 1 }}>{name}</Typography>
      </TableCell>
      <TableCell className={classes.cellContent}>
        <Typography variant="subtitle2" sx={{ paddingLeft: 1 }}>{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0, dealAccuracy = (showAccuracy) => showAccuracy, notificationInfo = null, showDeviceStatusDesc = false }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const admin = useAdministrator();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const hours12 = usePreference('twelveHourFormat');

  const readonly = useRestriction('readonly');
  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const positionAttributes = usePositionAttributes(t);
  const detailPositionItems = useAttributePreference('positionItems', 'speed,address,totalDistance,course');
  const briefPositionItems = useAttributePreference('briefPositionItems', 'fixTime,speed,power,address');
  const [positionItems, setPositionItems] = useState(briefPositionItems);
  const [infoBrief, setInfoBrief] = useState(false);
  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const handleGeofence = useCatchCallback(async () => {
    const newItem = {
      name: '',
      area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
  }, [navigate, position]);

  const transfromCoordinatesToBaidu = (coordinate) => {
    const coordinatesType = getPersistedState('coordinatesType', gcoord.GCJ02);
    if (coordinatesType !== gcoord.Baidu) {
      return gcoord.transform(coordinate, coordinatesType, gcoord.Baidu);
    }
    return coordinate;
  };

  const [lng, lat] = desktop && position ? transfromCoordinatesToBaidu([position.longitude, position.latitude]) : [0, 0];
  let alarmVal = null;
  if (position) {
    if (position.alarm) {
      alarmVal = position.alarm;
    } else if (position.attributes) {
      alarmVal = position.attributes.alarm;
    }
  }
  const alarmStr = formatAlarm(alarmVal, t);
  const getPositionContent = (key, showDeviceStatusDesc, position, device, dealAccuracy, t) => {
    if (key !== 'speed' || !showDeviceStatusDesc) {
      return (
        <PositionValue
          position={position}
          property={position.hasOwnProperty(key) ? key : null}
          attribute={position.hasOwnProperty(key) ? null : key}
          dealAccuracy={dealAccuracy}
        />
      );
    }
    if (device.motionState) {
      return (
        <PositionValue
          position={position}
          property={position.hasOwnProperty(key) ? key : null}
          attribute={position.hasOwnProperty(key) ? null : key}
          dealAccuracy={dealAccuracy}
        />
      );
    }
    // 显示停留状态
    return formatDeviceStatusDesc(device, t);
  };
  return (
    <>
      <div className={classes.root}>
        {device && (
          <Draggable
            handle={`.${classes.media}, .${classes.header}`}
          >
            <Card elevation={6} className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                avatar={(
                  <StyledBadge
                    device={device}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar src={`/api/media/${device.uniqueId}/${deviceImage}`} />
                  </StyledBadge>
                )}
                action={(
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                  >
                    <CloseIcon className={classes.CloseIcon} />
                  </IconButton>
                )}
                title={(
                  <Typography variant="subtitle1" sx={{ color: '#181818' }}>
                    {device.name}
                  </Typography>
                )}
                subheader={!notificationInfo && (
                  <div>
                    {showDeviceStatusDesc && <span style={{ color: `${getStatusColorHex(device.status)}` }}>{formatDeviceStatusDesc(device, t)}</span>}
                    <span style={{ color: `${getStatusColorHexByName('warnning')}`, marginLeft: 5 }}>{alarmStr}</span>
                    <Link
                      variant="body1"
                      style={{ marginLeft: '5px', color: '#007FFF' }}
                      onClick={() => {
                        setInfoBrief(!infoBrief);
                        if (infoBrief) {
                          setPositionItems(briefPositionItems);
                        } else {
                          setPositionItems(detailPositionItems);
                        }
                      }}
                    >
                      {infoBrief ? t('statusCardWindowCollapse') : t('statusCardWindowExpand')}
                    </Link>
                  </div>
                )}
              />
              <CardContent className={classes.content}>
                <Table size="small" classes={{ root: classes.table }}>
                  <TableBody>
                    <StatusRow
                      key="deviceIdentifier"
                      name={t('deviceIdentifier')}
                      content={device.uniqueId}
                    />
                    <StatusRow
                      key="deviceLastUpdate"
                      name={t('deviceLastUpdate')}
                      content={formatTime(device.lastUpdate, 'seconds', hours12)}
                    />
                    {notificationInfo && (
                      <>
                        <StatusRow
                          key="notificationType"
                          name={t('notificationType')}
                          content={notificationInfo.title}
                        />
                        {notificationInfo.geofenceName && (
                          <StatusRow
                            key="notificationGeofenceName"
                            name={t('sharedGeofence')}
                            content={notificationInfo.geofenceName}
                          />
                        )}
                        <StatusRow
                          key="notificationTime"
                          name={t('notificationTime')}
                          content={notificationInfo.time}
                        />
                      </>
                    )}
                    {position && positionItems.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                      <StatusRow
                        key={key}
                        name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                        content={getPositionContent(key, showDeviceStatusDesc, position, device, dealAccuracy, t)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
              {!notificationInfo && (
                <CardActions classes={{ root: classes.actions }}>
                  <Tabs variant="scrollable" value={false}>
                    <Tab classes={{ root: classes.tab }} icon={<TrackReplayIcon fontSize="small" />} label={t('reportReplayShort')} onClick={() => navigate('/replay')} disabled={disableActions || !position} />
                    <Tab classes={{ root: classes.tab }} icon={<SendCommandIcon fontSize="small" />} label={t('positionCommand')} onClick={() => navigate(`/settings/device/${deviceId}/command`)} disabled={disableActions} />
                    {!readonly && !desktop && <Tab classes={{ root: classes.tab }} icon={<WechatNotificationIcon fontSize="small" />} label={t('wechatNotification')} onClick={() => navigate(`/settings/device/${deviceId}/wechatNotifications`)} disabled={disableActions} />}
                    {!readonly && <Tab classes={{ root: classes.tab }} icon={<MultiAdminIcon fontSize="small" />} label={t('deviceMultiAdmin')} onClick={() => navigate(`/settings/device/${deviceId}/multiAdmin`)} disabled={disableActions} />}
                    {position && desktop && <Tab classes={{ root: classes.tab }} icon={<StreetViewIcon fontSize="small" />} label={t('linkStreetView')} onClick={() => window.open(`https://map.earthol.org/baidu/?x=${lng}&y=${lat}&h=0&p=0&z=1`)} />}
                    {position && admin && <Tab classes={{ root: classes.tab }} icon={<RouteIcon fontSize="small" />} label={t('sharedShowDetails')} onClick={() => navigate(`/position/${position.id}`)} disabled={disableActions || !position} />}
                    {position && <Tab classes={{ root: classes.tab }} icon={<GeofenceIcon fontSize="small" />} label={t('sharedCreateGeofence')} onClick={handleGeofence} disabled={disableActions} />}
                    {position && <Tab classes={{ root: classes.tab }} icon={<EditDeviceIcon fontSize="small" />} label={t('sharedEdit')} onClick={() => navigate(`/settings/device/${deviceId}`)} disabled={disableActions || deviceReadonly} />}
                    {position && admin && <Tab classes={{ root: classes.tab }} icon={<DeleteDeviceIcon fontSize="small" />} label={t('sharedRemove')} onClick={() => setRemoving(true)} disabled={disableActions || deviceReadonly} />}
                  </Tabs>
                </CardActions>
              )}
            </Card>
          </Draggable>
        )}
      </div>
      <RemoveDialog
        title="deviceDeleteTitle"
        content="deviceDeleteContent"
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};

export default StatusCard;
