import {
  amber, grey, green, red, common, orange,
} from '@mui/material/colors';

const colors = {
  white: common.white,
  background: grey[50],
  primary: '#007FFF',
  secondary: green[800],
  positive: green[500],
  medium: amber[700],
  negative: red[500],
  neutral: grey[500],
  geometry: '#3bb2d0',
  warnning: orange[800],
};

export default {
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  negative: {
    main: colors.negative,
  },
  divider: '#E5E8EC',
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
  },
  text: {
    disabled: 'rgba(0, 0, 0, 0.38)',
    icon: 'rgba(255, 255, 255, 0.5)',
    primary: '#20262D',
    secondary: '#2F3A45',
  },
  colors,
};
