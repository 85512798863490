import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import wx from 'weixin-js-sdk';
import theme from './common/theme';
import BottomMenu from './common/components/BottomMenu';
import SocketController from './SocketController';
import CachingController from './CachingController';
import { useEffectAsync } from './reactHelper';
import { sessionActions } from './store';
import usePersistedState from './common/util/usePersistedState';

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: 'auto',
  },
  menu: {
    zIndex: 4,
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const newServer = useSelector((state) => state.session.server.newServer);
  const initialized = useSelector((state) => !!state.session.user);

  const [wechatRealAuthUrl] = usePersistedState('wechatRealAuthUrl', '');
  useEffectAsync(async () => {
    if (!initialized) {
      const response = await fetch('/api/session');
      if (response.ok) {
        const resp = await fetch(`/api/session/jsapiSignature?url=${encodeURIComponent(wechatRealAuthUrl)}`);
        if (resp.ok) {
          const wxconfig = await resp.json();
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: wxconfig.appId, // 必填，公众号的唯一标识
            timestamp: wxconfig.timestamp, // 必填，生成签名的时间戳
            nonceStr: wxconfig.nonceStr, // 必填，生成签名的随机串
            signature: wxconfig.signature, // 必填，签名
            jsApiList: [ // 必填，需要使用的JS接口列表'checkJsApi',
              'scanQRCode',
              // 'onMenuShareTimeline',
              // 'onMenuShareAppMessage',
              // 'onMenuShareQQ',
              // 'onMenuShareWeibo',
              // 'hideMenuItems',
              // 'showMenuItems',
              // 'hideAllNonBaseMenuItem',
              // 'showAllNonBaseMenuItem',
              // 'translateVoice',
              // 'startRecord',
              // 'stopRecord',
              // 'onRecordEnd',
              // 'playVoice',
              // 'pauseVoice',
              // 'stopVoice',
              // 'uploadVoice',
              // 'downloadVoice',
              // 'chooseImage',
              // 'previewImage',
              // 'uploadImage',
              // 'downloadImage',
              // 'getNetworkType',
              // 'openLocation',
              // 'getLocation',
              // 'hideOptionMenu',
              // 'showOptionMenu',
              // 'closeWindow',
              // 'openProductSpecificView',
              // 'addCard',
              // 'chooseCard',
              // 'openCard',
              // 'openWXDeviceLib',
              // 'closeWXDeviceLib',
              // 'configWXDeviceWiFi',
              // 'getWXDeviceInfos',
              // 'sendDataToWXDevice',
              // 'startScanWXDevice',
              // 'stopScanWXDevice',
              // 'connectWXDevice',
              // 'disconnectWXDevice',
              // 'getWXDeviceTicket',
              // 'WeixinJSBridgeReady',
              // 'onWXDeviceBindStateChange',
              // 'onWXDeviceStateChange',
              // 'onScanWXDeviceResult',
              // 'onReceiveDataFromWXDevice',
              // 'onWXDeviceBluetoothStateChange',
            ],
          });
          wx.error((res) => {
            console.dir(res.errMsg);
            alert(res.errMsg);
          });
        }
        dispatch(sessionActions.updateUser(await response.json()));
      } else if (newServer) {
        wx.miniProgram.getEnv((res) => {
          if (!res.miniprogram) {
            navigate('/register');
          }
        });
      } else {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            wx.miniProgram.navigateTo({
              url: '/pages/login/login',
            });
          }
        });
        navigate('/login');
      }
    }
    return null;
  }, [initialized]);

  return !initialized ? (<LinearProgress />) : (
    <>
      <SocketController />
      <CachingController />
      <div className={classes.page}>
        <Outlet />
      </div>
      {!desktop && (
        <div className={classes.menu}>
          <BottomMenu />
        </div>
      )}
    </>
  );
};

export default App;
