import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapRoutePoints from '../map/MapRoutePoints';
import MapPositionsReplay from '../map/MapPositionsReplay';
import ReportFilter from '../reports/components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch } from '../reactHelper';
import MapCamera from '../map/MapCamera';
import MapGeofence from '../map/MapGeofence';
import StatusCard from '../common/components/StatusCard';
import ReplayPageToolBar from './ReplayPageToolBar';
import { formatTime } from '../common/util/formatter';
import { usePreference } from '../common/util/preferences';
import MapPositionStartEnd from '../map/MapPositionStartEnd';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      left: 0,
      top: 0,
      height: `calc(100% - ${theme.spacing(3)})`,
      width: theme.dimensions.drawerWidthDesktop,
      margin: theme.spacing(1.5),
      zIndex: 3,
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
    },
  },
  header: {
    pointerEvents: 'auto',
    zIndex: 6,
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
  },
  middle: {
    flex: 1,
    display: 'grid',
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  contentList: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
    zIndex: 4,
  },
}));

const ReplayPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const timerRef = useRef();
  const defaultDeviceId = useSelector((state) => state.devices.selectedId);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);
  const [selectedDeviceId, setSelectedDeviceId] = useState(defaultDeviceId);
  const [timeSelectOpen, setTimeSelectOpen] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [playSpeed, setPlaySpeed] = useState(500);
  const [playSpeedRange, setPlaySpeedRange] = useState('trackReplaySpeedNormal');
  const hours12 = usePreference('twelveHourFormat');

  useEffect(() => {
    switch (playSpeedRange) {
      case 'trackReplaySpeedSlow':
        setPlaySpeed(1000);
        break;
      case 'trackReplaySpeedNormal':
        setPlaySpeed(500);
        break;
      case 'trackReplaySpeedFast':
        setPlaySpeed(100);
        break;
      default:
        setPlaySpeed(500);
    }
  }, [playSpeedRange]);

  useEffect(() => {
    if (playing && positions.length > 0) {
      clearInterval(timerRef.current);
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, playSpeed);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, positions, playSpeed]);

  useEffect(() => {
    if (index >= positions.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, positions]);

  const onPointClick = useCallback((_, index) => {
    setIndex(index);
  }, [setIndex]);

  const onMarkerClick = useCallback((positionId) => {
    setShowCard(!!positionId);
  }, [setShowCard]);

  const handleSubmit = useCatch(async ({ deviceId, from, to }) => {
    console.log(`from ${from} to ${to}`);
    setFrom(from);
    setTo(to);
    setSelectedDeviceId(deviceId);
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/positions?${query.toString()}`);
    if (response.ok) {
      setIndex(0);
      const positions = await response.json();
      setPositions(positions);
      if (positions.length) {
        setShowCard(selectedDeviceId);
        setTimeSelectOpen(false);
      } else {
        throw Error(t('sharedNoData'));
      }
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <div className={classes.root}>
      {desktop && (
        <>
          <MapView>
            <MapGeofence />
            <MapRoutePath positions={positions} />
            <MapRoutePoints positions={positions} onClick={onPointClick} />
            {(playing || index !== 0) && index < positions.length && (
              <MapPositionsReplay positions={[positions[index]]} onClick={onMarkerClick} titleField="fixTime" />
            )}
            <MapPositionStartEnd positions={positions} />
          </MapView>
          <MapCamera positions={positions} />
        </>
      )}
      <div className={classes.sidebar}>
        <Paper square elevation={3} className={classes.header}>
          <ReplayPageToolBar
            deviceId={selectedDeviceId}
            from={from}
            to={to}
            max={positions.length - 1}
            marks={positions.map((_, index) => ({ value: index }))}
            value={index}
            onChange={(_, index) => setIndex(index)}
            playing={playing}
            setPlaying={setPlaying}
            disabled={index >= positions.length - 1}
            timeSelectOpen={timeSelectOpen}
            setTimeSelectOpen={setTimeSelectOpen}
            playSpeedRange={playSpeedRange}
            setPlaySpeedRange={setPlaySpeedRange}
            playContent={positions[index] ? `${formatTime(positions[index].fixTime, 'seconds', hours12)}` : ''}
          />
        </Paper>
        <div className={classes.middle}>
          {!desktop && (
            <div className={classes.contentMap}>
              <MapView>
                <MapGeofence />
                <MapRoutePath positions={positions} />
                <MapRoutePoints positions={positions} onClick={onPointClick} />
                {(playing || index !== 0) && index < positions.length && (
                  <MapPositionsReplay positions={[positions[index]]} onClick={onMarkerClick} titleField="fixTime" />
                )}
                <MapPositionStartEnd positions={positions} />
              </MapView>
              <MapCamera positions={positions} />
            </div>
          )}
          <Paper square className={classes.contentList} style={timeSelectOpen ? {} : { visibility: 'hidden' }}>
            <ReportFilter handleSubmit={handleSubmit} fullScreen showOnly />
          </Paper>
        </div>
      </div>
      {showCard && index < positions.length && (
        <StatusCard
          deviceId={selectedDeviceId}
          position={positions[index]}
          onClose={() => setShowCard(false)}
          disableActions
        />
      )}
    </div>
  );
};

export default ReplayPage;
