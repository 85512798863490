import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';
import { devicesActions } from '../store';
import { useEffectAsync } from '../reactHelper';
import DeviceRow from './DeviceRow';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
}));

const DeviceList = ({ devices }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listInnerEl = useRef(null);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, []);
  const calcItemSize = (index) => {
    const device = devices[index];
    const { simCardstatus, simCardPackagetime } = device;
    const simCardPackagetimeMoment = simCardPackagetime ? moment(simCardPackagetime) : null;
    const now = new Date();
    const simcardPackageTimeDiffDays = simCardPackagetimeMoment ? simCardPackagetimeMoment.diff(moment(now), 'days') : 0;
    const conditionA = simCardstatus === 1 && simcardPackageTimeDiffDays > 0 && simcardPackageTimeDiffDays < 15;
    const itemSize = conditionA || simCardstatus === 2 ? 100 : 80;
    return itemSize;
  };
  return (
    <AutoSizer className={classes.list}>
      {({ height, width }) => (
        <VariableSizeList
          width={width}
          height={height}
          itemCount={devices.length}
          itemData={devices}
          itemSize={calcItemSize}
          overscanCount={10}
          innerRef={listInnerEl}
        >
          {DeviceRow}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
};

export default DeviceList;
