import './geocoder.css';
import maplibregl from 'maplibre-gl';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { map } from '../core/MapView';
import { errorsActions } from '../../store';

const MapGeocoder = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const geocoder = {
      forwardGeocode: async (config) => {
        const features = [];
        try {
          const request = `/api/server/poi?keywords=${config.query}`;
          const response = await fetch(request);
          const geojson = await response.json();
          geojson.pois.forEach((poi) => {
            const { name, location, address } = poi;
            const center = location.split(',');
            features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: center,
              },
              place_name: `${name}-${address}`,
              place_type: ['place'],
              center,
            });
          });
        } catch (e) {
          dispatch(errorsActions.push(e.message));
        }
        return { features };
      },
    };

    const control = new MaplibreGeocoder(geocoder, {
      maplibregl,
      collapsed: true,
      limit: 10,
    });
    map.addControl(control);
    return () => map.removeControl(control);
  }, [dispatch]);

  return null;
};

export default MapGeocoder;
