import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useAdministrator } from '../../common/util/permissions';
import RemoveDialog from '../../common/components/RemoveDialog';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },
}));

const CollectionActions = ({
  itemId, editPath, endpoint, setTimestamp, customActions, readonly,
}) => {
  const classes = useStyles();
  const admin = useAdministrator();
  const navigate = useNavigate();
  const [, setMenuAnchorEl] = useState(null);
  const [removing, setRemoving] = useState(false);

  const handleEdit = () => {
    navigate(`${editPath}/${itemId}`);
    setMenuAnchorEl(null);
  };

  const handleRemove = () => {
    setRemoving(true);
    setMenuAnchorEl(null);
  };

  const handleCustom = (action) => {
    action.handler(itemId);
    setMenuAnchorEl(null);
  };

  const handleRemoveResult = (removed) => {
    setRemoving(false);
    if (removed) {
      setTimestamp(Date.now());
    }
  };

  return (
    <>
      <div className={classes.row}>
        {customActions && customActions.map((action) => (
          <IconButton size="small" color="secondary" onClick={() => handleCustom(action)} key={action.key}>
            {action.icon}
          </IconButton>
        ))}
        {!readonly && (
          <>
            <IconButton size="small" onClick={handleEdit}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
            {admin && (
              <IconButton size="small" onClick={handleRemove}>
                <DeleteIcon fontSize="small" color="negative" />
              </IconButton>
            )}
          </>
        )}
      </div>
      <RemoveDialog style={{ transform: 'none' }} open={removing} endpoint={endpoint} itemId={itemId} onResult={handleRemoveResult} />
    </>
  );
};

export default CollectionActions;
