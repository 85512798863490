import React, {
  useState, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import SimCardIcon from '@mui/icons-material/SimCard';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MRT_Localization_ZH_HANS as mRTLocalizationZHhANS } from 'material-react-table/locales/zh-Hans';
// eslint-disable-next-line import/no-extraneous-dependencies
import MaterialReactTable from 'material-react-table';
// eslint-disable-next-line import/no-extraneous-dependencies
import { QueryClient, QueryClientProvider, useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from '../common/components/LocalizationProvider';
import { usePreference } from '../common/util/preferences';
import { getSimcardStatusDesc, formatForeverTime } from '../common/util/formatter';
import PageLayout from '../common/components/PageLayout';
import CollectionActions from './components/CollectionActions';
import CollectionFab from './components/CollectionFab';
import SettingsMenu from './components/SettingsMenu';
import PayDialog from '../common/components/PayDialog';
import { useDeviceReadonly, useAdministrator } from '../common/util/permissions';
import { wechatActionSheetOption } from '../common/util/other';
import theme from '../common/theme';

const queryClient = new QueryClient();
const fetchSize = 50;

const Devices = () => {
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const deviceReadonly = useDeviceReadonly();
  const admin = useAdministrator();
  const t = useTranslation();
  const hours12 = usePreference('twelveHourFormat');
  const [timestamp, setTimestamp] = useState(Date.now());

  const tableContainerRef = useRef(null); // we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef = useRef(null); // we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [sorting, setSorting] = useState([]);
  const [payOpen, setPayOpen] = useState(false);
  const [payDeviceId, setPayDeviceId] = useState(null);
  const [payProductType, setPayProductType] = useState(null);
  const devices = useSelector((state) => state.devices.items);

  const columns = admin ? [
    {
      accessorKey: 'name',
      header: t('sharedName'),
      size: 120,
    },
    {
      accessorKey: 'uniqueId',
      header: t('deviceIdentifier'),
      size: 140,
    },
    {
      accessorKey: 'iccid',
      header: t('simcardIccid'),
      size: 130,
    },
    {
      accessorFn: (row) => getSimcardStatusDesc(t, row.simCardstatus),
      header: t('simcardCardStatus'),
      enableColumnFilter: false,
      size: 100,
    },
    {
      accessorFn: (row) => formatForeverTime(row.simCardFinalPackageTime, 'date', hours12),
      header: t('simCardFinalPackageTime'),
      enableColumnFilter: false,
      size: 120,
    },
    {
      accessorKey: 'simCardPackagetime',
      header: t('simcardPackageTime'),
      enableColumnFilter: false,
      size: 120,
    },
    {
      accessorKey: 'model',
      header: t('deviceModel'),
      size: 80,
    },
    {
      accessorKey: 'contact',
      header: t('deviceContact'),
      size: 80,
    },
    {
      accessorFn: (row) => formatForeverTime(row.expirationTime, 'date', hours12),
      header: t('userExpirationTime'),
      size: 120,
    },
  ] :
    [
      {
        accessorKey: 'name',
        header: t('sharedName'),
        size: 120,
      },
      {
        accessorKey: 'uniqueId',
        header: t('deviceIdentifier'),
        size: 140,
      },
      {
        accessorKey: 'iccid',
        header: t('simcardIccid'),
        size: 130,
      },
      {
        accessorFn: (row) => formatForeverTime(row.simCardFinalPackageTime, 'date', hours12),
        header: t('simCardFinalPackageTime'),
        enableColumnFilter: false,
        size: 120,
      },
      {
        accessorKey: 'model',
        header: t('deviceModel'),
        size: 80,
      },
      {
        accessorKey: 'contact',
        header: t('deviceContact'),
        size: 80,
      },
      {
        accessorFn: (row) => formatForeverTime(row.expirationTime, 'date', hours12),
        header: t('userExpirationTime'),
        size: 120,
      },
    ];

  const actionConnections = {
    key: 'connections',
    title: t('sharedConnections'),
    icon: <LinkIcon fontSize="small" />,
    handler: (deviceId) => navigate(`/settings/device/${deviceId}/connections`),
  };

  const actionDeviceMsgSub = {
    key: 'wechatMsg',
    title: t('wechatMsg'),
    icon: <NotificationImportantIcon fontSize="small" />,
    handler: (deviceId) => {
      const device = devices[deviceId];
      const otherData = {
        uniqueId: device.uniqueId,
        name: device.name,
      };
      wechatActionSheetOption('msgSub', t('wechatMsg'), otherData);
    },
  };

  const actionDeviceServiceDays = {
    key: 'serviceDays',
    title: t('serviceDays'),
    icon: <FingerprintIcon fontSize="small" />,
    handler: (deviceId) => {
      setPayDeviceId(deviceId);
      setPayProductType('ServiceTime');
      setPayOpen(true);
    },
  };

  const actionDeviceSimCard = {
    key: 'SimCard',
    title: t('SimCard'),
    icon: <SimCardIcon fontSize="small" />,
    handler: (deviceId) => {
      setPayDeviceId(deviceId);
      setPayProductType('SimCard');
      setPayOpen(true);
    },
  };

  const renderRowActions = ({ row }) => (
    <div style={{
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '0.5rem',
    }}
    >
      <CollectionActions
        itemId={row.original.id}
        editPath="/settings/device"
        endpoint="devices"
        setTimestamp={setTimestamp}
        customActions={desktop ? [actionConnections] : [actionDeviceServiceDays, actionDeviceSimCard, actionConnections, actionDeviceMsgSub]}
        readonly={deviceReadonly}
      />
    </div>
  );

  const { data, fetchNextPage, isError, isFetching, isLoading } =
    useInfiniteQuery({
      queryKey: ['table-data', columnFilters, globalFilter, sorting],
      queryFn: async ({ pageParam = 0 }) => {
        const url = new URL(
          '/api/devices/paging',
          window.location.origin,
        );
        url.searchParams.set('start', `${pageParam * fetchSize}`);
        url.searchParams.set('size', `${fetchSize}`);
        url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        url.searchParams.set('globalFilter', globalFilter ?? '');
        url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
        const response = await fetch(url.href);
        const json = await response.json();
        return json;
      },
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

  const flatData = useMemo(
    () => data?.pages.flatMap((page) => page.data) ?? [],
    [data],
  );

  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
  const totalFetched = flatData.length;

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within 1000px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 1000 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  // scroll to top of table when sorting or filters change
  useEffect(() => {
    // scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting, columnFilters, globalFilter]);

  // a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);
  // 增删数据后重新抓取数据
  useEffect(() => {
    queryClient.refetchQueries();
  }, [timestamp]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDrivers']}>
      <MaterialReactTable
        columns={columns}
        data={flatData}
        initialState={{ density: 'compact' }}
        enablePagination={false}
        enableBottomToolbar={false}
        enableRowNumbers={false}
        enableRowVirtualization // optional, but recommended if it is likely going to be more than 100 rows
        manualFiltering
        enableSorting={false}
        enableGlobalFilter={false}
        muiTableContainerProps={{
          ref: tableContainerRef, // get access to the table container element
          onScroll: (
            event, // add an event listener to the table container element
          ) => fetchMoreOnBottomReached(event.target),
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
              color: 'error',
              children: t('sharedNoData'),
            }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} // get access to the virtualizer instance
        rowVirtualizerProps={{ overscan: 4 }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={renderRowActions}
        localization={{ ...mRTLocalizationZHhANS, actions: '操作' }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            minSize: 20,
            maxSize: 200,
            size: 120,
          },
        }}
      />
      <PayDialog
        open={payOpen}
        productType={payProductType}
        payDeviceId={payDeviceId}
        onResult={() => setPayOpen(false)}
      />
      <CollectionFab editPath="/settings/device" />
    </PageLayout>
  );
};

const DevicesPage = () => (
  <QueryClientProvider client={queryClient}>
    <Devices />
  </QueryClientProvider>
);

export default DevicesPage;
