import React from 'react';

import { Slider, Box } from '@mui/material';

export default function ReplayPageProgressBar({ max, marks, value, onChange, sx: psx }) {
  const sx = psx;
  // NaN on division by zero
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        wrap: 'nowrap',
        alignItems: 'center',
        '& > .children': {
          mx: 1,
        },
        paddingLeft: '1rem',
        ...sx,
      }}
    >
      <Slider
        className="children"
        aria-labelledby="continuous-slider"
        max={max}
        step={null}
        marks={marks}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
}
