import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Snackbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync, useCatch } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import TableShimmer from '../common/components/TableShimmer';
import { snackBarDurationShortMs } from '../common/util/duration';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  root: {
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(1)})`,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 2, 2),
  },
  button: {
    height: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.colors.negative,
  },
}));

const RemoveDialog = ({ deviceId, userId, setTimestamp }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const doRemove = useCatch(async () => {
    const response = await fetch(`/api/devices/${deviceId}/${userId}/multiAdmin`, { method: 'DELETE' });
    if (response.ok) {
      setRemoveDialogOpen(false);
      setTimestamp(Date.now());
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <>
      <IconButton size="small" onClick={() => setRemoveDialogOpen(true)}>
        <DeleteIcon fontSize="small" color="negative" />
      </IconButton>
      <Dialog
        className={classes.root}
        open={removeDialogOpen}
      >
        <DialogTitle>{t('deviceMultiAdminRemove')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('deviceMultiAdminRemoveDesc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" sx={{ mr: 2 }} onClick={doRemove}>{t('sharedRemove')}</Button>
          <Button size="small" variant="contained" onClick={() => setRemoveDialogOpen(false)}>{t('sharedCancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeviceMultiAdmin = () => {
  const classes = useStyles();
  const t = useTranslation();
  const user = useSelector((state) => state.session.user);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [showItems, setShowItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addError, setAddError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [helperText, setHelperText] = useState(null);
  const [multiAdminCode, setMultiAdminCode] = useState('');
  const [onlyRemove, setOnlyRemove] = useState(false);
  const { id } = useParams();

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/devices/${id}/multiAdmin`);
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  useEffect(() => {
    const onlyItem = items.find((item) => item.multiAdminCode === user.multiAdminCode);
    if (onlyItem) {
      setShowItems([onlyItem]);
      setOnlyRemove(true);
    } else {
      setShowItems(items);
    }
  }, [items, user]);

  const checkCode = () => {
    let result = false;
    if (multiAdminCode.length === 0) {
      setHelperText(t('deviceMultiAdminAddDescCodeNotExist'));
      result = false;
    } else {
      result = true;
      setHelperText(null);
    }
    setAddError(!result);
    return result;
  };

  const addManageUser = useCatch(async () => {
    if (!checkCode()) {
      return;
    }
    const response = await fetch(`/api/devices/${id}/${multiAdminCode}/multiAdmin`, { method: 'POST' });
    if (response.ok) {
      setSnackbarOpen(true);
      setMultiAdminCode('');
      setHelperText(t('deviceMultiAdminAddMsgSuccess'));
      setTimestamp(new Date());
    } else {
      if (response.status === 400) {
        setAddError(true);
        setHelperText(t('deviceMultiAdminAddDescAccountError'));
      }
      if (response.status !== 400) {
        throw Error(await response.text());
      }
    }
  });

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'deviceMultiAdmin']}>
      {!onlyRemove && (
        <div className={classes.header}>
          <TextField
            sx={{ minWidth: 280 }}
            variant="outlined"
            error={addError}
            helperText={helperText}
            value={multiAdminCode}
            onChange={(e) => { setAddError(false); setHelperText(null); setMultiAdminCode(e.target.value); }}
            label={t('deviceMultiAdminAdd')}
            placeholder={t('deviceMultiAdminAddDesc')}
          />
          <IconButton color="primary" aria-label={t('deviceMultiAdminAdd')} sx={{ marginLeft: '10px' }} onClick={addManageUser}>
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow sx={{ fontWeight: 600 }}>
            <TableCell>{t('deviceMultiAdminCode')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? showItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.multiAdminCode}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <RemoveDialog
                  deviceId={id}
                  userId={item.id}
                  setTimestamp={setTimestamp}
                />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={2} endAction />)}
        </TableBody>
      </Table>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackBarDurationShortMs}
        message={t('deviceMultiAdminAddMsgSuccess')}
        onClose={() => setSnackbarOpen(false)}
      />
    </PageLayout>
  );
};

export default DeviceMultiAdmin;
