import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
// import gcoord from 'gcoord';
import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import store, { sessionActions, geofencesActions } from '../../store';
import { SwitcherControl } from '../switcher/switcher';
import { useAttributePreference, usePreference } from '../../common/util/preferences';
import usePersistedState, { savePersistedState } from '../../common/util/usePersistedState';
import { mapImages } from './preloadImages';
import useMapStyles from './useMapStyles';

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';
element.style.boxSizing = 'initial';

export const map = new maplibregl.Map({
  container: element,
  attributionControl: false,
});

let ready = false;
const readyListeners = new Set();

const addReadyListener = (listener) => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = (listener) => {
  readyListeners.delete(listener);
};

const updateReadyValue = (value) => {
  ready = value;
  readyListeners.forEach((listener) => listener(value));
};

const initMap = async () => {
  if (ready) return;
  if (!map.hasImage('background')) {
    Object.entries(mapImages).forEach(([key, value]) => {
      map.addImage(key, value, {
        pixelRatio: window.devicePixelRatio,
      });
    });
  }
  updateReadyValue(true);
};

map.addControl(new maplibregl.NavigationControl());

// 更新session中的地图坐标系类别
const updateMapCoordinatesTypeSession = async (mapCoordinatesType) => {
  const response = await fetch('/api/map-coordinates-type', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: mapCoordinatesType,
  });
  if (!response.ok) {
    throw Error(await response.text());
  }
};

// 重新获取最新位置数据并更新store
const refreshPositions = async (from, to) => {
  // console.log(`from:${from} to:${to}`);
  if (from !== to) {
    const positionsResponse = await fetch('/api/positions');
    if (positionsResponse.ok) {
      const positions = await positionsResponse.json();
      // console.dir(positions[0]);
      // positions.map((position) => {
      //   const { longitude, latitude } = position;
      //   const [lng, lat] = gcoord.transform([longitude, latitude], from, to);
      //   position.longitude = lng;
      //   position.latitude = lat;
      //   return position;
      // });
      // console.dir(positions[0]);
      store.dispatch(sessionActions.updatePositions(positions));
    } else {
      throw Error(await positionsResponse.text());
    }

    const response = await fetch('/api/geofences');
    if (response.ok) {
      store.dispatch(geofencesActions.update(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }
};

let coordinatesTypeBefore = null;
const switcher = new SwitcherControl(
  () => {
    coordinatesTypeBefore = switcher.currentCoordinatesType;
    updateReadyValue(false);
  },
  (styleId, style) => {
    savePersistedState('selectedMapStyle', styleId);
    savePersistedState('coordinatesType', style.coordinatesType);
    // 切换地图后，需先向后台设置坐标系类型，后台后续返回的是纠偏后的位置数据
    updateMapCoordinatesTypeSession(style.coordinatesType);
    // 刷新store中的位置数据
    refreshPositions(coordinatesTypeBefore, style.coordinatesType);
  },
  () => {
    map.once('styledata', () => {
      const waiting = () => {
        if (!map.loaded()) {
          setTimeout(waiting, 33);
        } else {
          initMap();
        }
      };
      waiting();
    });
  },
);

map.addControl(switcher);

const MapView = ({ children }) => {
  const containerEl = useRef(null);

  const [mapReady, setMapReady] = useState(false);

  const mapStyles = useMapStyles();
  const activeMapStyles = useAttributePreference('activeMapStyles', 'autoNavi,locationIqStreets,osm,carto');
  const [defaultMapStyle] = usePersistedState('selectedMapStyle', usePreference('map', 'autoNavi'));
  const mapboxAccessToken = useAttributePreference('mapboxAccessToken');
  const maxZoom = useAttributePreference('web.maxZoom');

  useEffect(() => {
    if (maxZoom) {
      map.setMaxZoom(maxZoom);
    }
  }, [maxZoom]);

  useEffect(() => {
    maplibregl.accessToken = mapboxAccessToken;
  }, [mapboxAccessToken]);

  useEffect(() => {
    const filteredStyles = mapStyles.filter((s) => s.available && activeMapStyles.includes(s.id));
    const styles = filteredStyles.length ? filteredStyles : mapStyles.filter((s) => s.id === 'osm');
    switcher.updateStyles(styles, defaultMapStyle);
  }, [mapStyles, defaultMapStyle]);

  useEffect(() => {
    // 在其他组件载入前向后台session中设置坐标系类型，后台会根据坐标系类型返回纠偏后的数据
    updateMapCoordinatesTypeSession(switcher.currentCoordinatesType);
  }, []);

  useEffect(() => {
    const listener = (ready) => setMapReady(ready);
    addReadyListener(listener);
    return () => {
      removeReadyListener(listener);
    };
  }, []);

  useLayoutEffect(() => {
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    map.resize();
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerEl}>
      {mapReady && children}
    </div>
  );
};

export default MapView;
