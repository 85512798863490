import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import palette from './palette';
import dimensions from './dimensions';
import components from './components';

const theme = createTheme({
  palette,
  dimensions,
  components,
});
// create common components overrides for both modes
const commonStyleOverrides = {
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '5px 5px 5px 5px',
      },
    },
  },
  MuiToggleButton: {
    defaultProps: {
      color: 'primary',
    },
  },

  MuiSlider: {
    styleOverrides: {
      thumb: {
        width: 15,
        height: 15,
        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
        '&:before': {
          boxShadow: '0 2px 1px 0 rgba(0,0,0,0.4)',
        },
        ':hover, &.Mui-focusVisible': {
          height: 15,
          width: 15,
          boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 16%)',
        },
        '&.Mui-active': {
          width: 20,
          height: 20,
        },
      },
    },
  },
};

// deepmerge common styles overrides with light or dark components overrides depending on mode
const mergedComponentStyles = deepmerge(
  { components: commonStyleOverrides },
);

const newTheme = createTheme(theme, mergedComponentStyles);

export default newTheme;
