import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplayPageProgressBar from './ReplayPageProgressBar';
import ReplayPageControls from './ReplayPageControls';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  playControl: {
    display: 'flex',
    height: '2rem',
  },
}));

const ReplayPageToolBar = ({
  timeSelectOpen,
  setTimeSelectOpen,
  max,
  marks,
  value,
  onChange,
  playing,
  setPlaying,
  disabled,
  playSpeedRange,
  setPlaySpeedRange,
  playContent = 'xxxx-xx-xx xx:xx:xx',
  deviceId,
  from,
  to,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleDownload = () => {
    const query = new URLSearchParams({ deviceId, from, to });
    window.location.assign(`/api/positions/kml?${query.toString()}`);
  };

  return (
    <>
      <div className={classes.toolbar}>
        <IconButton edge="start" sx={{ marginLeft: '0.5rem' }} onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="button" display="block" gutterBottom>
          {playContent}
        </Typography>
        <IconButton edge="end" onClick={() => setTimeSelectOpen(!timeSelectOpen)}>
          {timeSelectOpen ? <CalendarTodayOutlinedIcon /> : <CalendarMonthIcon />}
        </IconButton>
        <IconButton onClick={handleDownload} disabled={!(deviceId && from && to)}>
          <DownloadIcon />
        </IconButton>
      </div>
      {!timeSelectOpen && (
        <div className={classes.playControl}>
          <ReplayPageProgressBar
            sx={{ flexGrow: 6 }}
            max={max}
            marks={marks}
            value={value}
            onChange={onChange}
          />
          <ReplayPageControls
            playing={playing}
            setPlaying={setPlaying}
            disabled={disabled}
            playSpeedRange={playSpeedRange}
            setPlaySpeedRange={setPlaySpeedRange}
          />
        </div>
      )}
    </>
  );
};

export default ReplayPageToolBar;
