import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Container, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, FormGroup, Autocomplete, TextField, createFilterOptions, Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation, useTranslationKeys } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { prefixString, unprefixString } from '../common/util/stringUtils';
import SelectField from '../common/components/SelectField';
import { useCatch } from '../reactHelper';
import { sessionActions } from '../store';
import { useRestriction } from '../common/util/permissions';

const deviceFields = [
  { id: 'name', name: 'sharedName' },
  { id: 'uniqueId', name: 'deviceIdentifier' },
  { id: 'phone', name: 'sharedPhone' },
  { id: 'model', name: 'deviceModel' },
  { id: 'contact', name: 'deviceContact' },
  { id: 'geofenceIds', name: 'sharedGeofences' },
];

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  tokenActions: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const PreferencesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const readonly = useRestriction('readonly');

  const user = useSelector((state) => state.session.user);
  const [attributes, setAttributes] = useState(user.attributes);

  const positionAttributes = usePositionAttributes(t);

  const filter = createFilterOptions();

  const alarms = useTranslationKeys((it) => it.startsWith('alarm')).map((it) => ({
    key: unprefixString('alarm', it),
    name: t(it),
  }));

  const handleSave = useCatch(async () => {
    const response = await fetch(`/api/users/${user.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...user, attributes }),
    });
    if (response.ok) {
      dispatch(sessionActions.updateUser(await response.json()));
      navigate(-1);
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedPreferences']}>
      <Container maxWidth="xs" className={classes.container}>
        {!readonly && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('mapTitle')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Autocomplete
                  multiple
                  freeSolo
                  options={Object.keys(positionAttributes)}
                  getOptionLabel={(option) => (positionAttributes.hasOwnProperty(option) ? positionAttributes[option].name : option)}
                  value={attributes.positionItems?.split(',') || ['speed', 'address', 'totalDistance', 'course']}
                  onChange={(_, option) => {
                    setAttributes({ ...attributes, positionItems: option.join(',') });
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue && !filtered.includes(params.inputValue)) {
                      filtered.push(params.inputValue);
                    }
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('attributePopupInfo')}
                    />
                  )}
                />
                <FormControl>
                  <InputLabel>{t('showLiveRoutes')}</InputLabel>
                  <Select
                    label={t('showLiveRoutes')}
                    value={attributes.mapLiveRoutes || 'none'}
                    onChange={(e) => setAttributes({ ...attributes, mapLiveRoutes: e.target.value })}
                  >
                    <MenuItem value="none">{t('notShow')}</MenuItem>
                    <MenuItem value="selected">{t('showWhenDeviceSelected')}</MenuItem>
                    <MenuItem value="all">{t('showOnAllDevices')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>{t('mapDirection')}</InputLabel>
                  <Select
                    label={t('mapDirection')}
                    value={attributes.mapDirection || 'selected'}
                    onChange={(e) => setAttributes({ ...attributes, mapDirection: e.target.value })}
                  >
                    <MenuItem value="none">{t('notShow')}</MenuItem>
                    <MenuItem value="selected">{t('showWhenDeviceSelected')}</MenuItem>
                    <MenuItem value="all">{t('showOnAllDevices')}</MenuItem>
                  </Select>
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={attributes.hasOwnProperty('mapGeofences') ? attributes.mapGeofences : true}
                        onChange={(e) => setAttributes({ ...attributes, mapGeofences: e.target.checked })}
                      />
                    )}
                    label={t('attributeShowGeofences')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={attributes.hasOwnProperty('mapFollow') ? attributes.mapFollow : false}
                        onChange={(e) => setAttributes({ ...attributes, mapFollow: e.target.checked })}
                      />
                    )}
                    label={t('deviceFollow')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={attributes.hasOwnProperty('mapCluster') ? attributes.mapCluster : true}
                        onChange={(e) => setAttributes({ ...attributes, mapCluster: e.target.checked })}
                      />
                    )}
                    label={t('mapClustering')}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={attributes.hasOwnProperty('mapOnSelect') ? attributes.mapOnSelect : true}
                        onChange={(e) => setAttributes({ ...attributes, mapOnSelect: e.target.checked })}
                      />
                    )}
                    label={t('mapOnSelect')}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('deviceListShowItem')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SelectField
                  emptyValue={null}
                  value={attributes.devicePrimary || 'name'}
                  onChange={(e) => setAttributes({ ...attributes, devicePrimary: e.target.value })}
                  data={deviceFields}
                  titleGetter={(it) => t(it.name)}
                  label={t('deviceListShowItemTitlePrimary')}
                />
                <SelectField
                  emptyValue=""
                  value={attributes.deviceSecondary || ''}
                  onChange={(e) => setAttributes({ ...attributes, deviceSecondary: e.target.value })}
                  data={deviceFields}
                  titleGetter={(it) => t(it.name)}
                  label={t('deviceListShowItemTitleSendory')}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedSound')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SelectField
                  multiple
                  value={attributes.soundEvents?.split(',') || []}
                  onChange={(e) => setAttributes({ ...attributes, soundEvents: e.target.value.join(',') })}
                  endpoint="/api/notifications/types"
                  keyGetter={(it) => it.type}
                  titleGetter={(it) => t(prefixString('event', it.type))}
                  label={t('eventsSoundEvents')}
                />
                <SelectField
                  multiple
                  value={attributes.soundAlarms?.split(',') || ['sos']}
                  onChange={(e) => setAttributes({ ...attributes, soundAlarms: e.target.value.join(',') })}
                  data={alarms}
                  keyGetter={(it) => it.key}
                  label={t('eventsSoundAlarms')}
                />
              </AccordionDetails>
            </Accordion>
            <div className={classes.buttons}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {t('sharedSave')}
              </Button>
            </div>
          </>
        )}
      </Container>
    </PageLayout>
  );
};

export default PreferencesPage;
