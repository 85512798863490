import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Stepper, Step, StepLabel, StepContent, Button, Typography, Box, List, ListItem, ListItemText, Switch, DialogActions, Dialog, DialogTitle, DialogContent, Stack,
} from '@mui/material';
import { useTranslation, useTranslationKeys } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import { prefixString, unprefixString } from '../common/util/stringUtils';
import { wechatActionSheetOption } from '../common/util/other';
import SettingsMenu from './components/SettingsMenu';
import { useEffectAsync } from '../reactHelper';
import Step1Img from '../resources/images/optHelp/1.png';
import Step2Img from '../resources/images/optHelp/2.png';
import Step3Img from '../resources/images/optHelp/3.png';
import Step4Img from '../resources/images/optHelp/4.png';
import Step5Img from '../resources/images/optHelp/5.png';

const AlarmTypeConfigration = ({ alarmTypes, alarmChecked, setAlarmChecked }) => {
  const t = useTranslation();
  const alarms = alarmTypes.map((it) => ({
    key: it,
    name: t(prefixString('alarm', it)),
  }));

  const handleToggle = (value) => () => {
    const currentIndex = alarmChecked.indexOf(value);
    const newChecked = [...alarmChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setAlarmChecked(newChecked);
  };

  return (
    <List component="div" disablePadding>
      {alarms && alarms.map((alarm) => (
        <ListItem key={alarm.key}>
          <ListItemText id="switch-list-label-bluetooth" primary={alarm.name} />
          <Switch
            edge="end"
            value={alarm.key}
            onChange={handleToggle(alarm.key)}
            checked={alarmChecked.indexOf(alarm.key) !== -1}
            inputProps={{
              'aria-labelledby': 'switch-list-label-bluetooth',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

const TypeConfigration = ({ notificationTypes, notificationChecked, setNotificationChecked }) => {
  const t = useTranslation();
  const handleToggle = (value) => () => {
    const currentIndex = notificationChecked.indexOf(value);
    const newChecked = [...notificationChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setNotificationChecked(newChecked);
  };

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
    >
      {notificationTypes && notificationTypes.map((notificationType) => (
        <ListItem key={notificationType}>
          <ListItemText id="switch-list-label-bluetooth" primary={t(prefixString('event', notificationType))} />
          <Switch
            edge="end"
            value={notificationType}
            onChange={handleToggle(notificationType)}
            checked={notificationChecked.indexOf(notificationType) !== -1}
            inputProps={{
              'aria-labelledby': 'switch-list-label-bluetooth',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

const OptHelp = ({ open = false, setOpen }) => {
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">开启微信消息通知指南</DialogTitle>
        <DialogContent dividers>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h6" gutterBottom>
              1. 点击 &quot;开启微信通知&quot;
            </Typography>
            <img width="100%" alt="点击开启微信通知" src={Step1Img} />
            <Typography variant="h6" gutterBottom>
              2. 点击 &quot;为此设备开启通知&quot;
            </Typography>
            <img width="100%" alt="为此设备开启通知" src={Step2Img} />
            <Typography variant="h6" gutterBottom>
              3. 选中 &quot;车辆定位器告警&quot;，2个都要选中!
            </Typography>
            <img width="100%" alt="选中车辆定位器告警" src={Step3Img} />
            <Typography variant="h6" gutterBottom>
              4. 选中 &quot;添加提醒&quot;，选填，开启后有声音提醒。
            </Typography>
            <img width="100%" alt="选中添加提醒" src={Step4Img} />
            <Typography variant="h6" gutterBottom>
              5. 点击 &quot;允许&quot;
            </Typography>
            <img width="100%" alt="点击允许" src={Step5Img} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>好的，我明白了</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const WechatNotificationsPage = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const defaultAlarmTypes = useTranslationKeys((it) => it.startsWith('alarm')).map((it) => unprefixString('alarm', it));
  const { id } = useParams();
  const [optHelpOpen, setOptHelpOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [alarmTypes, setAlarmTypes] = useState(defaultAlarmTypes);
  const [alarmChecked, setAlarmChecked] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [notificationChecked, setNotificationChecked] = useState([]);
  const [device, setDevice] = useState(null); // 需要用户微信订阅时device才不会为null
  const steps = [
    {
      label: '配置报警类型',
    },
    {
      label: '配置其他通知类型',
    },
    {
      label: '开启微信消息通知',
    },
  ];

  useEffectAsync(async () => {
    const response = await fetch(`/api/notifications/notificationsAndAlarmsAllowed/${id}`);
    if (response.ok) {
      const data = await response.json();
      const { supportAlarms, supportNotifications, defaultNotificationTypes } = data;
      if (supportAlarms) {
        setAlarmTypes(supportAlarms);
      }
      if (supportNotifications) {
        setNotificationTypes(supportNotifications);
      } else {
        setNotificationTypes(defaultNotificationTypes);
      }
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/notifications/weChatNotification/get/${id}?alarm=true`);
    if (response.ok) {
      const data = await response.json();
      setAlarmChecked(data);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/notifications/weChatNotification/get/${id}?alarm=true`);
    if (response.ok) {
      const data = await response.json();
      setAlarmChecked(data);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/notifications/weChatNotification/get/${id}?alarm=false`);
    if (response.ok) {
      const data = await response.json();
      setNotificationChecked(data);
    } else {
      throw Error(await response.text());
    }
  }, []);

  const addNotification = async (data) => {
    const response = await fetch(`/api/notifications/weChatNotification/edit/${id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const data = await response.json();
      const { id } = data;
      if (id) { // 需要用户完成微信消息订阅
        setDevice(data);
      }
    } else {
      throw Error(await response.text());
    }
  };

  const weChatDeviceMsgSub = (item) => {
    const otherData = {
      deviceId: item.id,
      uniqueId: item.uniqueId,
      name: item.name,
    };
    wechatActionSheetOption('msgSub', t('wechatMsg'), otherData);
  };

  const handleNext = () => {
    if (activeStep === 1) { // 提交用户选择的类型
      const data = {
        alarmChecked,
        notificationChecked,
      };
      addNotification(data);
    } else if (activeStep === 2) { // 处理用户微信订阅或者直接完成
      if (device) {
        weChatDeviceMsgSub(device);
      }
      navigate('/');
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedNotifications']}>
      <Stepper sx={{ padding: 2 }} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography variant="h6">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              {index === 0 && (
                <AlarmTypeConfigration
                  alarmTypes={alarmTypes}
                  alarmChecked={alarmChecked}
                  setAlarmChecked={setAlarmChecked}
                />
              )}
              {index === 1 && (
                <TypeConfigration
                  notificationTypes={notificationTypes}
                  notificationChecked={notificationChecked}
                  setNotificationChecked={setNotificationChecked}
                />
              )}
              { index === 2 && device && (
                <>
                  <Typography>这是最关键的一步！只要您登录着微信，就能收到报警通知，而且是100%的到达率呦＾ω＾，再也不会错过关键提醒了！</Typography>
                  <Button onClick={() => setOptHelpOpen(true)}>点我查看操作教程</Button>
                </>
              )}
              {index === 2 && !device && (
                <Typography>您已经为该设备开启微信通知了，直接点“完成”就OK了＾ω＾！</Typography>
              )}
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? (device ? '开启微信通知' : '完成') : '下一步'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    上一步
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <OptHelp
        open={optHelpOpen}
        setOpen={setOptHelpOpen}
      />
    </PageLayout>
  );
};

export default WechatNotificationsPage;
