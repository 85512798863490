import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton, Tooltip, Avatar, ListItemAvatar, ListItemText, ListItemButton, Button, Dialog, DialogTitle, DialogActions, Link, Stack,
} from '@mui/material';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';
import { devicesActions } from '../store';
import {
  formatAlarm, formatBoolean, formatPercentage, getStatusColor, getStatusColorHex, formatDeviceStatusDesc, getStatusColorHexByName,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { useAttributePreference } from '../common/util/preferences';
import PayDialog from '../common/components/PayDialog';

const useStyles = makeStyles(() => ({
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: getStatusColorHexByName('positive'),
  },
  medium: {
    color: getStatusColorHexByName('medium'),
  },
  negative: {
    color: getStatusColorHexByName('negative'),
  },
  neutral: {
    color: getStatusColorHexByName('neutral'),
  },
  warnning: {
    color: getStatusColorHexByName('warnning'),
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();

  const admin = useAdministrator();
  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const geofences = useSelector((state) => state.geofences.items);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');

  const [expirateDialogOpen, setExpirateDialogOpen] = useState(false);
  const [payOpen, setPayOpen] = useState(false);
  const [payDeviceId, setPayDeviceId] = useState(null);
  const [payProductType, setPayProductType] = useState(null);

  const payServiceDays = () => {
    setPayDeviceId(item.id);
    setPayProductType('ServiceTime');
    setPayOpen(true);
  };

  const formatProperty = (key) => {
    if (key === 'geofenceIds') {
      const geofenceIds = item[key] || [];
      return geofenceIds
        .filter((id) => geofences.hasOwnProperty(id))
        .map((id) => geofences[id].name)
        .join(', ');
    }
    return item[key];
  };

  const secondaryText = () => {
    const now = new Date();
    const expirationTime = moment(item.expirationTime);
    const expirate = expirationTime.isBefore(now);
    let diffDays = null;
    if (expirate) {
      diffDays = moment(now).diff(expirationTime, 'days');
    } else {
      diffDays = expirationTime.diff(moment(now), 'days');
    }
    const { simCardstatus, simCardPackagetime } = item;
    const simCardPackagetimeMoment = simCardPackagetime ? moment(simCardPackagetime) : null;
    const simcardPackageTimeDiffDays = simCardPackagetimeMoment ? simCardPackagetimeMoment.diff(moment(now), 'days') : 0;
    return (
      <>
        {deviceSecondary && item[deviceSecondary] && `${formatProperty(deviceSecondary)} • `}
        {!expirate && (
          <span className={classes[getStatusColor(item.status)]}>{formatDeviceStatusDesc(item, t)}</span>
        )}
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          {expirate && (
            <span className={classes.negative}>
              {`${t('deviceExpirateAfterDays')}${diffDays}${t('sharedDays')}`}
            </span>
          )}
          {!expirate && diffDays <= 14 && (
            <span className={classes.warnning}>
              {`${diffDays}${t('deviceExpirateBeforeDays')}`}
              &nbsp;&nbsp;
              <Link
                component="button"
                variant="body2"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/pay/ServiceTime/${item.id}`);
                }}
              >
                {t('deviceBuyServiceDays')}
              </Link>
            </span>
          )}
          {simCardstatus === 1 && simcardPackageTimeDiffDays > 0 && simcardPackageTimeDiffDays < 15 && (
            <span className={classes.warnning}>
              {`${t('simcard')}${simcardPackageTimeDiffDays}${t('simcardExpirateBeforeDays')}`}
              &nbsp;&nbsp;
              <Link
                component="button"
                variant="body2"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/pay/SimCard/${item.id}`);
                }}
              >
                {t('simcardBuyServiceDays')}
              </Link>
            </span>
          )}
          {simCardstatus === 2 && (
            <span className={classes.negative}>
              {`${t('simcardHasExpired')}`}
              &nbsp;&nbsp;
              <Link
                component="button"
                variant="body2"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/pay/SimCard/${item.id}`);
                }}
              >
                {t('simcardBuyServiceDays')}
              </Link>
            </span>
          )}
        </span>
      </>
    );
  };

  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => {
          if (moment(item.expirationTime).isBefore(new Date())) {
            setExpirateDialogOpen(true);
          } else {
            dispatch(devicesActions.selectId(item.id));
          }
        }}
        disabled={!admin && item.disabled}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: getStatusColorHex(item.status) }}>
            <img className={classes.icon} src={mapIcons[mapIconKey(item.category)]} alt="" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={formatProperty(devicePrimary)}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
        <Stack direction="column">
          <Stack direction="row">
            {position && (
              <>
                {position.attributes.hasOwnProperty('alarm') && (
                  <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                    <IconButton size="small">
                      <ErrorIcon fontSize="small" className={classes.negative} />
                    </IconButton>
                  </Tooltip>
                )}
                {position.attributes.hasOwnProperty('ignition') && (
                  <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                    <IconButton size="small">
                      {position.attributes.ignition ? (
                        <EngineIcon width={20} height={20} className={classes.positive} />
                      ) : (
                        <EngineIcon width={20} height={20} className={classes.neutral} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {position.attributes.hasOwnProperty('batteryLevel') && (
                  <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                    <IconButton size="small">
                      {position.attributes.batteryLevel > 70 ? (
                        position.attributes.charge
                          ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                          : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
                      ) : position.attributes.batteryLevel > 30 ? (
                        position.attributes.charge
                          ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                          : (<Battery60Icon fontSize="small" className={classes.medium} />)
                      ) : (
                        position.attributes.charge
                          ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                          : (<Battery20Icon fontSize="small" className={classes.negative} />)
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </ListItemButton>
      <Dialog key={index} className={classes.root} open={expirateDialogOpen}>
        <DialogTitle sx={{ textAlign: 'center' }}>{`${t('deviceHasExpired')}`}</DialogTitle>
        <DialogActions>
          <Button size="small" variant="contained" color="secondary" sx={{ mr: 2, pl: 5, pr: 5 }} onClick={() => { payServiceDays(); setExpirateDialogOpen(false); }}>
            {t('deviceBuyServiceDays')}
          </Button>
          <Button size="small" variant="contained" color="error" onClick={() => { setExpirateDialogOpen(false); }}>{t('sharedCancel')}</Button>
        </DialogActions>
      </Dialog>
      <PayDialog
        key={data.length + index + 1}
        open={payOpen}
        productType={payProductType}
        payDeviceId={payDeviceId}
        onResult={() => setPayOpen(false)}
      />
    </div>
  );
};

export default DeviceRow;
