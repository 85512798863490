import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogActions, Button, DialogContent, ToggleButtonGroup, ToggleButton, FormControl, OutlinedInput, InputAdornment, Typography, Card, CardHeader, CardContent, CardActions,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from './LocalizationProvider';
import { useEffectAsync } from '../../reactHelper';
import { wechatActionSheetOption } from '../util/other';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(1)})`,
    },
  },
  button: {
    height: 'auto',
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.colors.negative,
  },
}));

const CustomPayDialog = ({ open, product, onResult }) => {
  const classes = useStyles();
  const t = useTranslation();
  const [customCount, setCustomCount] = useState('');

  const dealCustomCounts = (event) => {
    const counts = event.target.value;
    if (counts >= 0) {
      setCustomCount(counts);
    }
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
    >
      <DialogTitle>{t('customServiceDays')}</DialogTitle>
      <DialogContent>
        <FormControl variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{product.unit}</InputAdornment>}
            type="number"
            value={customCount}
            onChange={dealCustomCounts}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button size="small" color="secondary" variant="contained" onClick={() => { onResult(customCount); }}>{t('confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const PayPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const { productType, payDeviceId } = useParams();
  const [customPayDialogOpen, setCustomPayDialogOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [customCount, setCustomCount] = useState(0);
  const [product, setProduct] = useState({ title: '', unit: '', price: 0 });
  useEffectAsync(async () => {
    if (productType) {
      const response = await fetch(`/api/pay/product?productType=${productType}`);
      if (response.ok) {
        const productItem = await response.json();
        setProduct(productItem);
        setCount(0);
        setCustomCount(0);
      } else {
        throw Error(await response.text());
      }
    }
  }, [productType]);

  const handlePay = () => {
    if (product) {
      const otherData = {
        deviceId: payDeviceId,
        product,
        count,
      };
      wechatActionSheetOption('wechatPay', product.title, otherData);
    }
  };
  const payChange = (event, counts) => {
    if (counts !== -1) {
      setCount(counts);
    } else {
      setCustomPayDialogOpen(true);
    }
  };

  const handleCustomCounts = (counts) => {
    setCustomPayDialogOpen(false);
    setCount(counts);
    setCustomCount(counts);
  };

  return (
    <Card
      className={classes.root}
    >
      <CardHeader>{product.title}</CardHeader>
      <CardContent>
        <Typography align="center" variant="subtitle1" sx={{ marginBottom: 2 }}>
          {/* {productST.price / 100}
          {productST.unit}
          /天, 共计: */}
          {product.price / 100}
          元/
          {product.unit}
          *
          {count}
          {product.unit}
          =
          {(product.price / 100) * count}
          元
        </Typography>
        {productType === 'ServiceTime' &&
          (
            <ToggleButtonGroup
              color="primary"
              value={count}
              exclusive
              onChange={payChange}
              aria-label="Platform"
            >
              <ToggleButton value={1}>
                1
                {product.unit}
              </ToggleButton>
              <ToggleButton value={7}>
                7
                {product.unit}
              </ToggleButton>
              <ToggleButton value={30}>
                30
                {product.unit}
              </ToggleButton>
              <ToggleButton value={150}>
                150
                {product.unit}
              </ToggleButton>
              <ToggleButton value={365}>
                365
                {product.unit}
              </ToggleButton>
              <ToggleButton value={-1}>
                {t('reportCustom')}
                {customCount > 0 && (
                  <Typography>
                    {customCount}
                    {product.unit}
                  </Typography>
                )}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        {productType === 'SimCard' &&
          (
          <ToggleButtonGroup
            color="primary"
            value={count}
            exclusive
            onChange={payChange}
            aria-label="Platform"
          >
            <ToggleButton value={1}>
              1
              {product.unit}
            </ToggleButton>
            <ToggleButton value={3}>
              3
              {product.unit}
            </ToggleButton>
            <ToggleButton value={6}>
              6
              {product.unit}
            </ToggleButton>
            <ToggleButton value={12}>
              12
              {product.unit}
            </ToggleButton>
            <ToggleButton value={-1}>
              {t('reportCustom')}
              {customCount > 0 && (
                <Typography>
                  {customCount}
                  {product.unit}
                </Typography>
              )}
            </ToggleButton>
          </ToggleButtonGroup>
          )}
      </CardContent>
      <CardActions>
        {count > 0 && <Button size="small" variant="contained" color="secondary" sx={{ mr: 2, pl: 5, pr: 5 }} onClick={handlePay}>{t('confirmPay')}</Button>}
        <Button size="small" variant="contained" onClick={() => { setCount(0); setCustomCount(0); navigate(-1); }}>{t('sharedCancel')}</Button>
      </CardActions>
      <CustomPayDialog
        open={customPayDialogOpen}
        product={product}
        onResult={handleCustomCounts}
      />
    </Card>
  );
};

export default PayPage;
