import React from 'react';

import IconButton from '@mui/material/IconButton';
import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/PauseRounded';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from '../common/components/LocalizationProvider';

export default function ReplayPageControls({ playing, setPlaying, disabled, playSpeedRange, setPlaySpeedRange, sx: psx }) {
  const sx = psx;
  const t = useTranslation();

  const changePlaySpeedRange = () => {
    switch (playSpeedRange) {
      case 'trackReplaySpeedSlow':
        setPlaySpeedRange('trackReplaySpeedNormal');
        break;
      case 'trackReplaySpeedNormal':
        setPlaySpeedRange('trackReplaySpeedFast');
        break;
      case 'trackReplaySpeedFast':
        setPlaySpeedRange('trackReplaySpeedSlow');
        break;
      default:
        setPlaySpeedRange('trackReplaySpeedNormal');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        ...sx,
      }}
    >
      <IconButton
        onClick={() => setPlaying(!playing)}
        disabled={disabled}
        size="middle"
        sx={{ color: '#007FFF' }}
      >
        {playing ? (
          <PauseIcon fontSize="middle" />
        ) : (
          <PlayIcon fontSize="middle" />
        )}
      </IconButton>
      <Button fontSize="middle" onClick={changePlaySpeedRange}>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {t(playSpeedRange)}
        </Typography>
      </Button>
    </Box>
  );
}
