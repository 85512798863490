import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../common/components/LocalizationProvider';
import BaseCommandView from './components/BaseCommandView';
import SelectField from '../common/components/SelectField';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { useCatch, useEffectAsync } from '../reactHelper';
import { useRestriction, useAdministrator } from '../common/util/permissions';
import { prefixString } from '../common/util/stringUtils';
import { formatTime } from '../common/util/formatter';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  tablecell: {
    padding: 0,
  },
  tablecellIcon: {
    padding: 2,
  },
}));

const Row = ({ row }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const responseResultDesc = (result) => {
    switch (result) {
      case 1:
        return '未知';
      case 2:
        return '成功';
      case 3:
        return '失败';
      default:
        return '';
    }
  };
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell className={classes.tablecellIcon}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tablecell} align="center">{formatTime(row.responsetime)}</TableCell>
        <TableCell className={classes.tablecell} align="center">{row.responsedesc ? row.responsedesc : '指令已发送,未执行。'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#eeeeee' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tablecell} align="center">创建时间</TableCell>
                    <TableCell className={classes.tablecell} align="center">发送时间</TableCell>
                    <TableCell className={classes.tablecell} align="center">上线发送</TableCell>
                    <TableCell className={classes.tablecell} align="center">执行结果</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.id}>
                    <TableCell className={classes.tablecell} component="th" scope="row" align="center">
                      {formatTime(row.createtime)}
                    </TableCell>
                    <TableCell className={classes.tablecell} align="center">{formatTime(row.sendtime)}</TableCell>
                    <TableCell className={classes.tablecell} align="center">{row.queued ? '是' : '否'}</TableCell>
                    <TableCell className={classes.tablecell} align="center">{responseResultDesc(row.responseresult)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

// const rows = [
//   {
//     id: 1,
//     description: '断油电',
//     queued: false,
//     createtime: '2023-08-31 13:55:51',
//     sendtime: '2023-08-31 13:55:52',
//     responsetime: '2023-08-31 13:55:53',
//     responseresult: 0,
//     responsedesc: '断油电,Cut off the fuel supply: Success!',
//   },
//   {
//     id: 2,
//     description: '断油电',
//     queued: false,
//     createtime: '2023-08-31 13:55:51',
//     sendtime: '2023-08-31 13:55:52',
//     responsetime: '2023-08-31 13:55:53',
//     responseresult: 1,
//     responsedesc: '断油电,Cut off the fuel supply: Success!',
//   },
//   {
//     id: 3,
//     description: '断油电',
//     queued: false,
//     createtime: '2023-08-31 13:55:51',
//     sendtime: '2023-08-31 13:55:52',
//     responsetime: '2023-08-31 13:55:53',
//     responseresult: 2,
//     responsedesc: '断油电,Cut off the fuel supply: Success!',
//   },
//   {
//     id: 4,
//     description: '断油电',
//     queued: false,
//     createtime: '2023-08-31 13:55:51',
//     sendtime: '2023-08-31 13:55:52',
//     responsetime: '2023-08-31 13:55:53',
//     responseresult: 3,
//     responsedesc: '断油电,Cut off the fuel supply: Success!',
//   },
// ];

const CollapsibleTable = ({ rows }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ fontSize: '0.5rem' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tablecell} />
            <TableCell className={classes.tablecell} sx={{ fontWeight: 700 }} align="center">执行时间</TableCell>
            <TableCell className={classes.tablecell} sx={{ fontWeight: 700 }} align="center">执行详情</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CommandSendHistoryDialog = ({ deviceId, open, setOpen }) => {
  const [rows, setRows] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };

  useEffectAsync(async () => {
    if (open) {
      const response = await fetch(`/api/commandSendHistory/${deviceId}/history`);
      if (response.ok) {
        setRows(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent sx={{ padding: 0 }}>
          <CollapsibleTable rows={rows} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CommandDevicePage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const t = useTranslation();
  const admin = useAdministrator();

  const { id } = useParams();

  const [savedId, setSavedId] = useState(0);
  const [item, setItem] = useState({});
  const [commandSendHistroyDialogOpen, setCommandSendHistroyDialogOpen] = useState(false);

  const limitCommands = useRestriction('limitCommands');

  const handleSend = useCatch(async () => {
    let command;
    if (savedId) {
      const response = await fetch(`/api/commands/${savedId}`);
      if (response.ok) {
        command = await response.json();
      } else {
        throw Error(await response.text());
      }
    } else {
      command = item;
    }

    command.deviceId = parseInt(id, 10);
    command.description = t(prefixString('command', command.type));
    const response = await fetch('/api/commands/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(command),
    });

    if (response.ok) {
      navigate(-1);
    } else {
      throw Error(await response.text());
    }
  });

  const validate = () => savedId || (item && item.type);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'deviceCommand']}>
      <Container maxWidth="xs" className={classes.container}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedRequired')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {admin && (
              <SelectField
                value={savedId}
                emptyValue={limitCommands ? null : 0}
                emptyTitle={t('sharedNew')}
                onChange={(e) => setSavedId(e.target.value)}
                endpoint={`/api/commands/send?deviceId=${id}`}
                titleGetter={(it) => it.description}
                label={t('sharedSavedCommand')}
              />
            )}
            {!limitCommands && !savedId && (
              <BaseCommandView deviceId={id} item={item} setItem={setItem} />
            )}
          </AccordionDetails>
        </Accordion>
        <div className={classes.buttons}>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            {t('sharedCancel')}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={handleSend}
            disabled={!validate()}
          >
            {t('commandSend')}
          </Button>
        </div>
        <div className={classes.buttons}>
          <Button sx={{ marginTop: 3 }} onClick={() => setCommandSendHistroyDialogOpen(true)}>
            {t('commandSendHistory')}
          </Button>
        </div>
        <CommandSendHistoryDialog
          deviceId={id}
          open={commandSendHistroyDialogOpen}
          setOpen={setCommandSendHistroyDialogOpen}
        />
      </Container>
    </PageLayout>
  );
};

export default CommandDevicePage;
