export const wechatActionSheetOption = async (opType, opTitle, otherData) => {
  const optData = { ...otherData, opType, opTitle };
  try {
    const response = await fetch('/api/notifications/wechatActionSheetOperation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(optData),
    });
    if (!response.ok) {
      throw Error(await response.text());
    }
  } catch (error) {
    throw Error(error.message);
  }
};

export default { wechatActionSheetOption };
