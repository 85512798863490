import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { map } from './core/MapView';
import { findFonts } from './core/mapUtil';
import { useAttributePreference } from '../common/util/preferences';

const MapPositionStartEnd = ({ positions }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const iconScale = useAttributePreference('iconScale', desktop ? 0.75 : 1);

  const startId = 'start';
  const endId = 'end';
  useEffect(() => {
    map.addSource(startId, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0],
        },
        properties: {},
      },
    });
    map.addSource(endId, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0],
        },
        properties: {},
      },
    });
    map.addLayer({
      id: startId,
      type: 'symbol',
      source: startId,
      layout: {
        'icon-image': 'replayStart-primary',
        'icon-size': iconScale,
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'text-anchor': 'bottom',
        'text-offset': [0, -2 * iconScale],
        'text-font': findFonts(map),
        'text-size': 12,
      },
      paint: {
        'text-halo-color': 'white',
        'text-halo-width': 1,
      },
    });

    map.addLayer({
      id: endId,
      type: 'symbol',
      source: endId,
      layout: {
        'icon-image': 'replayEnd-negative',
        'icon-size': iconScale,
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'text-anchor': 'bottom',
        'text-offset': [0, -2 * iconScale],
        'text-font': findFonts(map),
        'text-size': 12,
      },
      paint: {
        'text-halo-color': 'white',
        'text-halo-width': 1,
      },
    });
    return () => {
      if (map.getLayer(startId)) {
        map.removeLayer(startId);
      }
      if (map.getLayer(endId)) {
        map.removeLayer(endId);
      }
      if (map.getSource(startId)) {
        map.removeSource(startId);
      }
      if (map.getSource(endId)) {
        map.removeSource(endId);
      }
    };
  }, [positions]);

  useEffect(() => {
    if (!positions || positions.length === 0) {
      return;
    }
    map.getSource(startId)?.setData({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [positions[0].longitude, positions[0].latitude],
      },
      properties: {},
    });
    map.getSource(endId)?.setData({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [positions[positions.length - 1].longitude, positions[positions.length - 1].latitude],
      },
      properties: {},
    });
  }, [positions]);

  return null;
};

export default MapPositionStartEnd;
